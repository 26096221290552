import { graphql } from "gatsby"
import { PageTemplate } from "./PageTemplate"
import { PageTemplateProps } from "./PageTemplateProps"

export default function PageFactory(props: PageTemplateProps) {
    return new PageTemplate(props)
}

export const pageQuery = graphql`
    query PageById($id: String!, $layout: String!, $mediasets: [String], $form: String) {
        page: allMarkdownRemark(filter: { frontmatter: { id: { eq: $id }, type: { eq: "page" } } }) {
            nodes {
                ...PageFragment
            }
        }
        layout: allMarkdownRemark(filter: { frontmatter: { id: { eq: $layout }, type: { eq: "layout" } } }) {
            nodes {
                ...NewLayoutFragment
            }
        }
        mediasets: allMarkdownRemark(filter: { frontmatter: { type: { eq: "mediaset" }, id: { in: $mediasets } } }) {
            nodes {
                ...MediaSetFragment
            }
        }
        form: allMarkdownRemark(filter: { frontmatter: { id: { eq: $form }, type: { eq: "form" } } }) {
            nodes {
                ...FormFragment
            }
        }
    }
`
